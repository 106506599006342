<template>
  <v-card class="mx-auto" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: 'servicios',
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span
            class="text-h6 ml-1"
            v-text="
              $route.meta.title +
              (tbl_data.length > 0 ? ' (' + tbl_data.length + ')' : '')
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <DatePicker
            :label="'F. inicial'"
            :model.sync="start_date"
            :disabled="tbl_data.length > 0"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <DatePicker
            :label="'F. final'"
            :model.sync="end_date"
            :disabled="tbl_data.length > 0"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-select
            label="Estado"
            v-model="pending"
            :items="tbl_filters"
            item-value="id"
            :item-text="(v) => v.value"
            dense
            :disabled="tbl_data.length > 0"
          />
        </v-col>
        <v-col v-if="tbl_data.length == 0" cols="12" xs="12" md="3">
          <v-btn
            block
            small
            color="success"
            @click.prevent="getTblData"
            :loading="loading"
            :disabled="pending == null"
          >
            Buscar
            <v-icon right> mdi-magnify </v-icon>
          </v-btn>
        </v-col>
        <v-col v-else cols="12" xs="12" md="3">
          <v-btn block small color="" @click.prevent="tbl_data = []">
            Cambiar parametros
            <v-icon right> mdi-refresh </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="tbl_search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
            :disabled="tbl_data.length == 0"
          />
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="tbl_headers"
            :search="tbl_search"
            :items="tbl_data"
            :loading="loading"
            :items-per-page="15"
            dense
          >
            <template v-slot:item.key="{ item }">
              <b v-text="item.key + 1" />
            </template>
            <template v-slot:item.discharged="{ item }">
              <span v-text="item.discharged ? item.discharged : 'PEND.'" />
            </template>
            <template v-slot:item.action="{ item }">
              <div class="text-right">
                <v-tooltip v-if="login.permissions.rss.read" left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="info"
                      x-small
                      fab
                      class="ml-1"
                      :to="{
                        name: 'servicios.detalle',
                        params: { id: item.rs_id },
                      }"
                      target="_blank"
                    >
                      <v-icon> mdi-eye </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Ver detalle'" />
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      :color="
                        item.rs_service_confirm_notes > 0 ? 'warning' : ''
                      "
                      x-small
                      fab
                      class="ml-1"
                      @click.prevent="rsServiceConfirmNotesDlg(item)"
                    >
                      <v-icon> mdi-note-multiple </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Notas administrativas'" />
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog
      v-model="rs_service_confirm_notes_dlg"
      persistent
      max-width="1200"
    >
      <v-card
        tile
        :disabled="rs_service_confirm_notes_dlg_ldg"
        :loading="rs_service_confirm_notes_dlg_ldg"
      >
        <v-toolbar dark>
          <v-toolbar-title>
            {{
              rs_service_confirm_note ? rs_service_confirm_note.folio_full : ""
            }}
            | Notas Administrativas
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="rs_service_confirm_notes_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="mt-3">
          <v-row v-for="(item, i) in rs_service_confirm_notes" :key="i" dense>
            <v-col cols="12" xs="12" md="6">
              <ViewData label="Fecha" :value="item.created_at" />
            </v-col>
            <v-col cols="12" xs="12" md="6">
              <ViewData label="Creado por" :value="item.created_by.email" />
            </v-col>
            <v-col cols="12">
              <ViewData label="Observación" :value="item.observation" />
            </v-col>
            <v-col cols="12">
              <v-divider />
            </v-col>
          </v-row>
          <v-form
            v-if="rs_service_confirm_note"
            v-on:submit.prevent
            ref="rs_service_confirm_note_form"
            lazy-validation
          >
            <v-row dense>
              <v-col cols="12">
                <v-textarea
                  label="Observación"
                  rows="3"
                  v-model="rs_service_confirm_note.observation"
                  dense
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn
                  small
                  color="warning"
                  @click.prevent="rsServiceConfirmNoteStore"
                >
                  Enviar
                  <v-icon right> mdi-send</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  dateTimeNow,
  dateTimeToFile,
  base64ToArrayBuffer,
  rules,
  msgConfirm,
  msgAlert,
} from "../../control";
import ViewData from "../../components/ViewData.vue";
import DatePicker from "../../components/DatePicker.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    ViewData,
    FaqDlg,
    DatePicker,
  },
  data() {
    return {
      login: this.$store.getters.getLogin,
      rules: rules(),
      loading: false,
      tbl_data: [],
      tbl_search: "",
      tbl_headers: [
        { text: "#", filterable: false, value: "key", width: "50" },
        { text: "Folio", filterable: true, value: "folio_full" },
        { text: "Tipo", filterable: true, value: "type" },
        {
          text: "Terminado",
          filterable: true,
          value: "discharged",
        },
        {
          text: "Proveedor | Médico",
          filterable: true,
          value: "provider_name",
        },
        {
          text: "Asegurado",
          filterable: true,
          value: "insured_full_name",
        },
        {
          text: "Oficina",
          filterable: true,
          value: "insured_office_address",
        },
        {
          text: "",
          fixed: true,
          value: "action",
          sortable: false,
          width: "110",
        },
      ],
      tbl_filters: [
        { id: 0, value: "PENDIENTES" },
        { id: 1, value: "CONFIRMADAS" },
      ],
      pending: null,
      start_date: null,
      end_date: null,
      item: null,
      rs_service_confirm_note: null,
      rs_service_confirm_notes: [],
      rs_service_confirm_notes_dlg: false,
      rs_service_confirm_notes_dlg_ldg: false,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    fileDownload(base64, ext, name) {
      const blob = new Blob([base64ToArrayBuffer(base64)], {
        type: "application/" + ext,
      });
      let downloadLink = document.createElement("a");
      downloadLink.setAttribute("target", "_blank");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "SM_" + name + "_" + dateTimeToFile() + "." + ext;
      downloadLink.click();
    },
    getTblData() {
      this.loading = true;
      this.tbl_data = [];

      Axios.get(
        URL_API +
          "/rss/services/confirm/list" +
          "?pending=" +
          this.pending +
          "&start_date=" +
          this.start_date +
          "&end_date=" +
          this.end_date,
        headersToken(this.login.token)
      ).then((res) => {
        this.tbl_data = res.data.data;
        this.loading = false;
      });
    },
    rsServiceConfirmNotesDlg(item) {
      this.item = item;
      this.rs_service_confirm_note = {
        id: null,
        active: true,
        observation: "",
        rs_service_id: item.id,
        folio_full: item.folio_full,
      };
      this.rs_service_confirm_notes = [];
      this.rs_service_confirm_notes_dlg_ldg = true;
      this.rs_service_confirm_notes_dlg = true;

      Axios.get(
        URL_API + "/rss/services/confirm/notes?rs_service_id=" + item.id,
        headersToken(this.login.token)
      ).then((res) => {
        this.rs_service_confirm_notes = res.data.data;
        this.rs_service_confirm_notes_dlg_ldg = false;
      });
    },
    rsServiceConfirmNoteStore() {
      if (this.$refs.rs_service_confirm_note_form.validate()) {
        this.$swal.fire(msgConfirm("¿Confirma enviar?")).then((res) => {
          if (res.isConfirmed) {
            this.rs_service_confirm_notes_dlg_ldg = true;

            Axios.post(
              URL_API + "/rss/services/confirm/notes",
              this.rs_service_confirm_note,
              headersToken(this.login.token)
            ).then((resp) => {
              this.$swal.fire(
                msgAlert(
                  resp.data.success ? "success" : "error",
                  resp.data.message
                )
              );

              if (resp.data.success) {
                this.rsServiceConfirmNotesDlg(this.item);
              } else {
                console.log(resp.data.message);
                this.rs_service_confirm_notes_dlg_ldg = false;
              }
            });
          }
        });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((res) => {
      this.faqs = res.data.data;
      this.faqs_ldg = false;
    });

    this.start_date = dateTimeNow().substring(0, 8) + "01";
    this.end_date = dateTimeNow().substring(0, 10);
  },
};
</script>